.cgu-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 10px;
  }
  
  ul {
    margin-bottom: 10px;
  }
  
  a {
    color: blue;
    text-decoration: underline;
  }
  
  a:hover {
    color: darkblue;
  }
  