/* Gallery.css */
.full-page-container {
    display: flex;
    flex-direction: column;
}

.gallery {
    display: flex;
    margin: 50px;
    margin-top: 0px;
    gap: 10px;
    max-height: 400px; /* Ajoutez une hauteur maximale ici */
    overflow: hidden; /* Cachez le contenu qui dépasse */
  }
  
  .gallery-title {
    text-align: left;
    margin-top: 100px; /* Ajustez selon l'espace nécessaire */
    font-size: 24px; /* Ajustez la taille de la police selon vos préférences */
    margin-left: 50px;
}

  .large {
    flex: 3;
    height: auto;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
    justify-content: space-between; /* Répartit uniformément l'espace entre les images */
  }
  
  .small {
    flex: 1;
    height: calc(50% - 5px); /* Ajustement pour le gap */
  }
  
  @media (max-width: 768px) {
    .gallery {
      flex-direction: column;
      margin: 0;
      max-height: none; /* En mode responsive, la hauteur maximale n'est pas nécessaire */
    }
    
    .large, .column {
      flex-basis: 100%;
    }
    
    .small {
      height: auto; /* La hauteur est automatique sur les appareils mobiles */
    }
  }
  
  /* Button.css */
.with-button {
    position: relative; /* Container relative pour le positionnement absolu du bouton */
  }
  
  .with-button img {
    display: block; /* Pour s'assurer que l'image remplit le conteneur */
    width: 100%; /* L'image prend toute la largeur du conteneur */
    height: auto; /* Hauteur automatique pour maintenir le ratio */
  }
  
  .view-all-button {
    position: absolute; /* Position absolue pour le bouton */
    bottom: 10px; /* En bas de la carte de l'image */
    left: 50%; /* Centré horizontalement sur la carte */
    transform: translateX(-50%); /* Décalage pour centrer exactement le bouton */
    padding: 8px 16px;
    border: none;
    background-color: rgba(255, 255, 255, 0.85); /* Légèrement transparent */
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .view-all-button:hover {
    background-color: rgba(255, 255, 255, 0.95); /* Moins transparent au survol */
  }
  
  @media (max-width: 768px) {
    .view-all-button {
      padding: 6px 12px;
    }
  }


.camping-details {
  display: flex;
  margin: 20px;
}

.left-section {
  flex: 2;
  padding-right: 20px;
}

.right-section {
  flex: 1;
}

.camping-details h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.camping-details ul {
  list-style: none;
  padding: 0;
}

.camping-details li {
  margin-bottom: 10px;
}

.camping-details img {
  max-width: 100%;
  height: auto;
  max-height: 150px; /* Ajustez la hauteur maximale selon vos préférences */
  display: block; /* Pour centrer correctement les images */
  margin: 0 auto;
}

.mobil-home-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobil-home-item div {
  margin-top: 10px; /* Ajustez la marge selon vos préférences */
  text-align: center;
}

.section-title {
  text-align: center;
  margin-bottom: 20px; /* Ajustez la marge selon vos préférences */
}

.gallery-modal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.gallery-modal-image {
  cursor: pointer;
}

.gallery-modal-image img {
  width: 100%;
  height: auto;
}

/* Add these styles to your MobilHomeView.css file or create a new CSS file */

.additional-section {
  display: flex;
  background-color: #f2f2f2; /* Light gray background color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the section */
}

.left-column,
.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px; /* Add margin between columns */
}

.left-column span,
.right-column span {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  margin-bottom: 10px; /* Add space between rows */
}

.left-column span svg,
.right-column span svg {
  margin-right: 10px; /* Add space between icon and text */
}

.additional-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px; /* Add space between columns and text */
}

/* Add these styles to your MobilHomeView.css file or create a new CSS file */

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Add space between rows */
}

.icon-column {
  margin-right: 10px; /* Add space between icon and text */
  font-size: 18px;
}

.text-column {
  flex: 1;
}

.text-strikethrough {
  text-decoration: line-through;
}
