.footer {
    background: #333;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none; /* Supprime les soulignements par défaut */
  }
  
  .footer-links a:hover {
    text-decoration: underline; /* Souligne les liens au survol */
  }
  
  
  
  .footer-copyright {
    margin-top: 15px;
  }
  