.contact-info {
    display: flex;
    align-items: center;
  }
  
  .image-container {
    margin-right: 20px;
  }
  
  .image-container img {
    width: 200px;
    height: auto;
  }
  
  .details {
    font-size: 16px;
  }
  
  /* Styles spécifiques au contenu */
  
  /* Pour ajuster la taille de l'image selon vos besoins */
  @media (max-width: 768px) {
    .image-container img {
      width: 150px;
    }
  }
  
  /* Pour modifier les couleurs et les polices */
  .details p {
    color: #333;
    font-family: Arial, sans-serif;
  }
  
  .details p:first-child {
    margin-bottom: 5px;
  }