.homepage-container {
  height: 100vh;
  background-image: url('../../assets/images/banner.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
}

.full-page-container {
  display: flex;
  flex-direction: column;
}

.homepage-text {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5rem;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.welcome-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: white;
  text-align: center;
  top: 30vh;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.scroll-arrow {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 3rem;
  animation: bounce 1s infinite;
}

.content-below-image {
  width: 100%;
  text-align: center;
  padding: 50px 0;
}

.title-below-image {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  transition: opacity 1s;
}

.fade-in.visible {
  opacity: 1;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-10px);
  }
  60% {
      transform: translateY(-5px);
  }
}

@media (max-width: 768px) {
  .welcome-text {
      top: 35vh;
  }

  .homepage-text {
      top: 45vh;
  }
}

.mobilhomes-container {
  display: flex;
  justify-content: space-between; /* Répartir les cadres en 1/3 */
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

.mobilhome {
  background: #f5f5f5;
  border-radius: 10px;
  text-align: center;
  width: calc(33.33% - 20px); /* Calculer la largeur en 1/3 moins les marges */
  margin: 0 10px;
  padding-bottom: 20px;
  transform: scale(1); /* Taille normale par défaut */
  transition: transform 0.3s;
}

.mobilhome-image {
  background-size: cover;
  background-position: center;
  height: 200px; /* Réglez la hauteur souhaitée */
  width: 100%;
  margin: 0; /* Supprimez les marges */
  border-radius: 10px 10px 0 0; /* Coin arrondi pour les coins supérieurs seulement */
}

.mobilhome h2 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.mobilhome p {
  font-size: 1rem;
  margin: 10px 0;
}

.mobilhome button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 1rem;
  cursor: pointer;
}

.mobilhome button:hover {
  background: #0056b3;
}

.mobilhome:hover {
  transform: scale(1.1); /* Zoom au survol */
  transition: transform 0.3s;
}

.navigation-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.navigation-button.left {
  left: 0;
}

.navigation-button.right {
  right: 0;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s;
}

.fade-in.visible {
  opacity: 1;
}

/* Ajoutez ces styles CSS dans votre fichier CSS ou dans un fichier séparé */

.above-mobilhomes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; /* Ajustez la marge en bas selon vos besoins */
}

.image-container {
  flex: 1;
  max-width: 50%; /* Ajustez la largeur maximale de l'image selon vos besoins */
  margin-top: 80px; /* Marge tout autour de l'image, ajustez au besoin */
  border-radius: 10px; /* Rayon de coin de l'image, ajustez au besoin */
  display: flex;
  justify-content: center; /* Centrer horizontalement */
  align-items: center; /* Centrer verticalement */
}

.image-container img {
  width: 85%;
  border-radius: 10px; /* Rayon de coin de l'image, ajustez au besoin */
}

.info-container {
  flex: 1;
  text-align: center;
}

.info-container h1 {
  background-color: #FFA500; /* Couleur de fond orange, ajustez au besoin */
  padding: 10px; /* Ajustez le padding du titre selon vos besoins */
  color: #fff; /* Couleur du texte, ajustez au besoin */
  margin-bottom: 10px; /* Ajustez la marge en bas du titre selon vos besoins */
  border-top-left-radius: 10px; /* Coin supérieur gauche arrondi */
    border-bottom-left-radius: 10px; /* Coin inférieur gauche arrondi */
}

.info-container p {
  padding: 10px; /* Ajustez la marge intérieure de la description selon vos besoins */
  color: #000; /* Couleur du texte, ajustez au besoin */
}