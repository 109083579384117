.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: transparent;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: background-color 0.3s;
  }
  
  .header.scrolled {
    background-color: white;
    color: #333;
  }
  
  .logo img {
    max-width: 50px;
    margin-left: 10px;
  }
  
  .navbar {
    flex-grow: 1;
  }
  
  .centered-items {
    display: flex;
    justify-content: right;
    width: 100%;
    list-style: none;
    padding: 0;
  }
  
  .contact-section a {
    background-color: #FFA803;
    padding: 10px 15px;
    border-radius: 3px;
    text-decoration: none;
    color: #fff;
    transition: 0.3s;
  }
  
  .contact-section a:hover {
    background-color: #e09803;
  }
  
  .navbar li {
    margin-right: 30px;
    cursor: pointer;
  }
  
  .navbar li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .navbar li a:hover {
    color: #FFA803;
  }
  
  .navbar li .active {
    color: #FFA803;
    font-weight: 700;
  }
  
  .header.scrolled .navbar li a {
    color: #333;
  }
  
  .header.scrolled .navbar li .active {
    color: #FFA803;
  }
  
  .menu-button {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .open {
    transform: none;
  }
  
  @media screen and (max-width: 768px) {
    .navbar {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      background-color: #333;
      text-align: center;
    }
  
    .navbar.open {
      display: flex;
    }
  
    .menu-button {
      display: flex;
    }
  
    .navbar.open .centered-items {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
    }
  
    .navbar.open li {
      margin: 10px 0;
    }
  }
  