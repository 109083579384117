/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  