.full-page-container {
    display: flex;
    flex-direction: column;
}

.cover-container {
    position: relative;
    text-align: center;
}

.cover-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    font-weight: bold;
    color: white;
}

.camping-name {
    font-weight: bold;
    color: black; /* Couleur du texte pour le nom du camping */
    font-size: 1.5em; /* Taille de police pour le nom du camping */
    margin-bottom: 5px; /* Marge en bas du nom du camping */
}

.camping-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px;
}

.camping-card {
    position: relative;
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
    overflow: hidden;
}

.camping-image {
    width: 100%;
    height: 250px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: filter 0.5s ease-in-out;
}

.camping-info {
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.camping-location {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.map-icon {
    margin-right: 5px;
    color: #555; /* Couleur de l'icône */
}

.see-more-button {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: #FFA803; /* Fond blanc semi-transparent */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.camping-card:hover .camping-image {
    filter: grayscale(0.7); /* Applique une opacité grise au survol */
}

.camping-card:hover .see-more-button {
    opacity: 1; /* Affiche le bouton "Voir plus" au survol */
}
