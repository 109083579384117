/* ImageCard.css */
.image-card {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .image-card img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Pour couvrir l'espace de la carte d'image sans déformation */
  }
  
  @media (max-width: 768px) {
    .image-card img {
      object-fit: contain; /* Pour montrer l'image complète sur les petits écrans */
    }
  }

  /* ImageCard.css */
.image-card img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out; /* Animation de transition pour l'effet de zoom */
    object-fit: cover; /* Assurez-vous que les images couvrent toute la carte */
    cursor: pointer;
  }
  
  .image-card:hover img {
    transform: scale(1.05); /* Zoom légèrement sur l'image */
  }